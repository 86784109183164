import { Typography } from '@mui/material';
import { lightBlue } from '../providers/ThemeRegistry/theme';

interface Props {
  title: string;
}

const Title = ({ title }: Props) => {
  return (
    <Typography
      sx={{
        '&::before': {
          content: '" "',
          position: 'absolute',
          background: lightBlue,
          width: '50px',
          height: '2px',
          marginRight: '26px',
          left: 0,
          top: '50%',
        },
      }}
      variant="h2"
    >
      {title}
    </Typography>
  );
};

export default Title;
