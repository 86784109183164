'use client';
import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import React from 'react';
import style from './styles';

interface NextLinkComposedProps
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
    Omit<NextLinkProps, 'href' | 'as'> {
  to: NextLinkProps['href'];
  linkAs?: NextLinkProps['as'];
}

export const NextLinkComposed = React.forwardRef<HTMLAnchorElement, NextLinkComposedProps>(function NextLinkComposed(
  props,
  ref,
) {
  const { to, linkAs, replace, scroll, shallow, prefetch, locale, ...other } = props;

  return (
    <NextLink
      href={to}
      prefetch={prefetch}
      as={linkAs}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
      passHref
      locale={locale}
      {...other}
      ref={ref}
    ></NextLink>
  );
});
export type LinkProps = {
  as?: NextLinkProps['as'];
  href: NextLinkProps['href'];
  // type?: 'text-underline' | 'text'; 
  // color?: 
  onClick?(): void;
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
  Omit<MuiLinkProps, 'href'>;

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(function Link(props, ref) {
  const { className, href, type, locale, prefetch, replace, scroll, shallow, color, onClick, ...other } = props;
  const nextjsProps = { to: href, replace, scroll, shallow, prefetch, locale };

  return (
    <MuiLink
      component={NextLinkComposed}
      onClick={onClick}
      ref={ref}
      sx={style}
      className={`${className} ${[`style-${type}`]}  ${[`color-${color}`]}`}
      {...nextjsProps}
      {...other}
    />
  );
});

export default Link;
