'use client';

import Modal from '@/components/Dialog';
import { Grid2 } from '@mui/material';
import { useSearchParams } from 'next/navigation';
import { useTranslation } from 'react-i18next';
import NewsBloc from './NewsBloc';
import OfficesBloc from './OfficesBloc';
import PresentationBloc from './PresentationBloc';
import style from './styles';

function HomePage() {
  const { t } = useTranslation('password');
  const searchParams = useSearchParams();
  const messageConfirm = searchParams.get('message');
  const error = searchParams.get('error');
  return (
    <Grid2 sx={style}>
      <Modal
        open={messageConfirm === 'confirmationMotDePasse' ? true : false}
        severity="success"
        message={t('confirmMessage')}
      />
      <Modal open={error === '1005' ? true : false} severity="success" message={t('passwordChanged')} />

      <PresentationBloc />
      <NewsBloc />
      <OfficesBloc />
    </Grid2>
  );
}

export default HomePage;
