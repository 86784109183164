import { Box, Grid2, Typography } from '@mui/material';
import style from './styles';
import Image from 'next/image';

interface Props {
  maintitle: string;
  text: string;
  image: string;
}

function ImageTextBloc(props: Props) {
  const { maintitle, text, image } = props;

  return (
    <Grid2 className="textBloc" sx={style}>
      <Box>
        <Typography variant="h6">{maintitle}</Typography>
        <Box className="text">
          <Typography variant="body2" dangerouslySetInnerHTML={{ __html: text }} />
        </Box>
      </Box>
      <Image className="officeImage" src={image} alt={image} width={290} height={290}></Image>
    </Grid2>
  );
}

export default ImageTextBloc;
