import { Document, DocumentEnum } from '@/services/api/swagger';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

export const formatEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

export const alphanumericsAndSpecialCharacters = /[a-zA-ZÀ-ú0-9!@#$&()\-_`'.+,\/"]+/;

export const formatPhoneNumber = /^(?:(?:\+)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;

export const formatApiDate = (date: string | Date): string => format(new Date(date), 'yyyy-MM-dd', { locale: fr });

export function getBase64(file?: File): Promise<string> | undefined {
  if (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }
}

/**
 * Formatte une date sous la forme "22/05/2000"
 *
 * @param date : Date d'entrée a formatter
 */
export const formatDateCourt = (date: string | Date): string => {
  if (typeof date === 'object') {
    return format(date, 'dd/MM/yyyy', { locale: fr });
  } else {
    const dateFormat = new Date(date);
    return format(dateFormat, 'dd/MM/yyyy', { locale: fr });
  }
};

export const buildFile = (content: string, type?: string) => {
  const byteCharacters = window.atob(content);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], { type: type || 'application/pdf' });

  return file;
};

export const buildBlob = (content: string, type?: string, name?: string) => {
  const byteCharacters = window.atob(content);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const file = new Blob([byteArray], { type: type || 'application/pdf' });
  // const fileURL = URL.createObjectURL(file);
  // window.open(fileURL);

  const link = document.createElement('a');
  link.href = URL.createObjectURL(file);
  link.download = name || 'fichier';
  link.click();
};

export const formatDateTimeCourt = (date: string | Date): string => {
  if (typeof date === 'object') {
    return format(date, 'dd/MM/yyyy HH:mm', { locale: fr });
  } else {
    const dateFormat = new Date(date);
    return format(dateFormat, 'dd/MM/yyyy HH:mm', { locale: fr });
  }
};

export const formatAlphaNumeric = /^[A-zÀ-ú0-9 ]*$/;

export function getDocumentAllFormatted(documentType: DocumentEnum, file?: File): Promise<Document> | undefined {
  if (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = () => {
        // const [, extension] = (reader.result as string).split(';')[0].split('/');
        const [, typeMime] = (reader.result as string).split(';')[0].split(':');
        const base64File = (reader.result as string).split(',')[1];
        // const renameExtension = extension === 'octet-stream' ? 'msg' : extension;
        const document = {
          name: `${file.name}`,
          content: base64File,
          documentType: documentType,
          typeMime,
        } as Document;
        resolve(document);
      };
      reader.onerror = (error) => reject(error);
    });
  }
}
