import { SxProps, Theme } from '@mui/material';
import { lightBlue } from '../providers/ThemeRegistry/theme';

const style: SxProps<Theme> = [
  {
    height: 'calc(342px - 96px)',
    marginTop: 8,
    background: '#F1F8FB',
    padding: '64px 32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h6': {
      fontSize: '30px',
      lineHeight: 1,
    },
    '& .text': {
      marginTop: 4,
      borderLeft: '3px solid ' + lightBlue,
      paddingLeft: 2,
    },

    '& .officeImage': {
      ml: 1,
      boxShadow: '5px 5px 12px 0 rgba(0, 0, 0, 0.28)',
    },
    '@media all and (max-width: 500px)': {
      '& .officeImage': {
        marginLeft: 2,
      },
    },

    '@media all and (max-width: 600px)': {
      '& .officeImage': {
        width: '171px',
        height: '171px',
        minWidth: 0,
      },
    },
  },
];

export default style;
