'use client';

import Button from '@/components/Button';
import { Grid2, Typography } from '@mui/material';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useTranslation } from 'react-i18next';

function PresentationBloc() {
  const { t } = useTranslation('presentation');
  const { push } = useRouter();

  const blocs = [
    {
      title: t('textCarline'),
      image: `/img/picto_contrôler_dossiers_location.jpg`,
    },
    {
      title: t('textCargo'),
      image: '/img/picto_consulter_sinistres.jpg',
    },
    {
      title: t('textDoc'),
      image: '/img/picto_consulter_contrats.jpg',
    },
  ];
  return (
    <Grid2 className="containerPresentation">
      <Grid2 className="container presentation">
        <Grid2 className="content">
          <Typography variant="h1" color="white" dangerouslySetInnerHTML={{ __html: t('textPresentation') }} />
          <Typography variant="h3" color="white">
            {t('textPresentation2')}
          </Typography>
          <Grid2 className="buttonsGrid">
            <Button
              title={t('connection')}
              variant="contained"
              handleClick={() => push('/redirect')}
              color="secondary"
            />

            <Button
              title={t('accidentDeclaration')}
              variant="outlined"
              handleClick={() => push('/declaration-sinistre')}
              color="secondary"
            />
          </Grid2>
          <Typography variant="body1" color="white" fontSize={'16px'}>
            {t('textPresentation3')}
          </Typography>
          <Grid2 className="blocs">
            {blocs.map((bloc) => (
              <Grid2 className="blocsContent" key={bloc.title}>
                <Image className="blocsContentImage" src={bloc.image} width={116} height={85} alt="image" />
                <Typography variant="body1">{bloc.title}</Typography>
              </Grid2>
            ))}
          </Grid2>
        </Grid2>
        <Link
          href="https://vertuloo.fr/?utm_source=immoplusenligne&utm_medium=site&utm_campaign=immoplusenligne"
          target="_blank"
          className="image"
        >
          <img src={`/img/Immo-Vertuloo.png`} alt="Vertuloo" />
        </Link>
      </Grid2>
    </Grid2>
  );
}

export default PresentationBloc;
