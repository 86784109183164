'use client';
import { SxProps, Theme } from '@mui/material';
import { theme } from '../providers/ThemeRegistry/theme';

const style: SxProps<Theme> = [
  {
    color: theme.palette.primary.main,
  },
];

export default style;
