import { SxProps, Theme } from '@mui/material';
import { theme } from '../../components/providers/ThemeRegistry/theme';

const style: SxProps<Theme> = [
  {
    '& .containerPresentation': {
      background: 'linear-gradient(231deg, #00559c 82%, #012b65)',
      maxHeight: '720px',
      mb: 11,
      '@media all and (max-width: 1115px)': {
        maxHeight: '100%',
        mb: 1,
      },
    },
    '& .container': {
      paddingTop: 8,
      justifyContent: 'space-between',
      width: 'clamp(45ch, 90%, 1400px)',
      margin: 'auto',
    },
    '& .presentation': {
      display: 'flex',
      paddingBottom: '128px',
      '@media all and (max-width: 1115px)': {
        flexDirection: 'column',
      },
      '& h3': {
        mb: 4,
        mt: 3,
      },
      '& .buttonsGrid': {
        display: 'flex',
        gap: 3,
        mb: 12,
        '@media all and (max-width: 1115px)': {
          mb: 4,
        },
      },
      '& .blocs': {
        display: 'flex',
        marginTop: 3,
        gap: '16px',
        '& > .blocsContent': {
          background: 'white',
          width: '198px',
          borderRadius: 2,
          boxShadow: '2px 2px 8px 0 rgba(0,0,0,0.2)',
          padding: '36px 24px',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          '@media all and (max-width: 700px)': {
            width: '100%',
          },
          '& p': {
            color: theme.palette.primary.main,
            lineHeight: 1,
            fontWeight: 700,
            marginTop: 2,
          },
        },
        '@media all and (max-width: 700px)': {
          flexDirection: 'column',
        },
      },
      '& .content': {
        flex: 1,
      },
      '& .image': {
        flex: 1,
        marginLeft: 8,
        position: 'relative',
        maxWidth: 623,
        width: '100%',
        '& img': {
          width: '100%',
          boxShadow: '7px 7px 16px 0 rgba(0, 0, 0, 0.28)',
        },
        '@media all and (max-width: 1115px)': {
          maxWidth: '100%',
          ml: 0,
          mt: 4,
          '& img': {
            height: '400px',
            objectFit: 'cover',
            objectPosition: '0 27%',
          },
        },
      },
    },
    '& .allNews': {
      maxWidth: '100%',
      display: 'flex',
      marginTop: 8,
      gap: 3,
      justifyContent: 'space-between',
      '@media all and (max-width: 1200px)': {
        flexWrap: 'wrap',
      },
      '@media all and (max-width: 600px)': {
        flexDirection: 'column',
      },
    },
    '& .offices': {
      mb: '94px',
    },
    '& .officesContent': {
      display: 'flex',
      width: '100%',
      gap: 4,
      '& > div': {
        width: '50%',
      },
      '@media all and (max-width: 1060px)': {
        flexDirection: 'column',
        gap: 2,
        '& > div': {
          width: 'calc(100% - 64px)',
          maxWidth: '700px',
          ml: 'auto',
          mr: 'auto',
        },
      },
    },
  },
];

export default style;
