'use client';
import { Box, Dialog, Typography } from '@mui/material';
import Button from '../Button';
import { success } from '../providers/ThemeRegistry/theme';
import { useRouter } from 'next/navigation';
import { Check } from '@mui/icons-material';

interface Props {
  severity: 'success' | 'error';
  message: string;
  open: boolean;
}

const Modal = ({ severity, message, open }: Props) => {
  const { push } = useRouter();
  return (
    <Dialog open={open}>
      <Box
        sx={{
          padding: 4,
          textAlign: 'center',
          '& svg': {
            color: success,
          },
          '& button': {
            mt: 1,
          },
          '& > p': {
            fontSize: 15,
          },
        }}
      >
        {severity === 'success' && <Check fontSize="large" />}
        <Typography variant="body2">{message}</Typography>
        <Button
          variant="contained"
          color="primary"
          title="Ok"
          handleClick={() => {
            push('/');
          }}
        />
      </Box>
    </Dialog>
  );
};

export default Modal;
