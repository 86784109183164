import ImageTextBloc from '@/components/ImageTextBloc';
import Title from '@/components/Title';
import { Box, Grid2 } from '@mui/material';
import { useTranslation } from 'react-i18next';

function OfficesBloc() {
  const { t } = useTranslation('offices');

  // setcookieAuthorized(true);

  return (
    <Grid2 className="container offices">
      <Box>
        <Title title={t('ourOffices')} />

        <Box className="officesContent">
          <ImageTextBloc maintitle={t('besseNantes')} text={t('addressNantes')} image={`/img/bureau_nantes.png`} />
          <ImageTextBloc maintitle={t('besseParis')} text={t('addressParis')} image={`/img/bureau_paris.jpg`} />
        </Box>
      </Box>
    </Grid2>
  );
}

export default OfficesBloc;
