import { Context } from '@/components/providers/ContextProvider';
import { IApiResponse } from '@/services/apiNews/actualites';
import { useContext } from 'react';
import { useMutation } from 'react-query';

export const useGetActualites = () => {
  const { apiNews } = useContext(Context);
  // @ts-expect-error : typescript bad
  return useMutation<IApiResponse, unknown, void>(
    // @ts-expect-error : typescript bad
    () => apiNews && apiNews.getActualites({ langue: 'fr' }),
    {
      onSuccess: () => {},
    },
  );
};
