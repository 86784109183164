import { SxProps, Theme } from '@mui/material';
import { theme } from '../providers/ThemeRegistry/theme';

const style: SxProps<Theme> = [
  {
    width: 'calc(100% / 3)',
    marginTop: '42px',
    boxShadow: '2px 2px 8px 0 rgba(0, 0, 0, 0.2)',
    padding: 1,
    margin: 2,
    '&:first-of-type': {
      marginLeft: 0,
    },
    '&:last-of-type': {
      marginRight: 0,
    },
    '& .image': {
      height: '230px',
      backgroundSize: 'cover',
      backgroundPosition: '50%',
    },
    '& .tag': {
      display: 'block',
      paddingTop: '20px',
      color: 'white',
      '& span': {
        fontStyle: 'italic',
        background: '#00559c',
        padding: '8px 12px',
        fontFamily: '"Playfair Display", serif',
      },
    },
    '& .contentActu': {
      marginTop: '18px',
    },
    '& .date': {
      paddingLeft: '16px',
      color: theme.palette.primary.light,
      fontSize: 14,
      fontWeight: 'bold',
    },
    '& .title': {
      padding: '8px 16px 16px 16px',
      fontFamily: theme.typography.body1.fontFamily,
      textTransform: 'uppercase',
      fontSize: 18,
      letterSpacing: 1,
    },
    '& .text': {
      display: 'flex',
      color: '#191919',
      padding: '0 16px 46px 16px',
      '& p': {
        flex: 1,
        lineHeight: '1.38',
      },
    },
    '& .trait': {
      background: 'rgba(96, 197, 219, 0.3)',
      width: '3px',
      height: '44px',
      marginRight: 2,
    },
    '& .link': {
      marginLeft: 4,
      display: 'flex',
      marginBottom: '28px',
      alignItems: 'center',
      '& span': {
        paddingLeft: 1,
        fontFamily: theme.typography.fontFamily,
        fontStyle: 'italic',
      },
    },
    '& .traitHorizontal': {
      height: '1px',
      background: theme.palette.primary.light,
      width: 52,
    },
    '@media all and (max-width: 1060px)': {
      marginLeft: '2%',
      marginRight: '2%',
      minWidth: 300,
      width: '90%',
      '&:first-of-type': {
        marginLeft: '2%',
      },
      '&:last-of-type': {
        marginRight: '2%',
      },
    },
  },
];

export default style;
