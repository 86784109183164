import SingleNewsBloc from '@/components/SingleNewsBloc';
import Title from '@/components/Title';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import { formatDateCourt } from '@/utils/formatters';
import { useGetActualites } from '@/hooks/useGetActualites';
import { useEffect } from 'react';

export interface Actu {
  datas: {
    title: string;
    intro: string;
    publication_date: string;
    type: {
      label: string;
    };
    url: string;
    img: string;
  };
}
function NewsBloc() {
  const [cookies, setCookie] = useCookies();

  const { mutate, data } = useGetActualites();

  const { t } = useTranslation('news');
  useEffect(() => {
    console.log(cookies['ImmoPlusCookies']);
    if (cookies['ImmoPlusCookies']?.isActualiteAutorisees) {
      mutate();
    }
  }, [cookies]);

  const setCookies = () => {
    setCookie('isActualiteAutorisees', 'true');
  };

  const actualites = data?.result;

  return (
    <div className="container news">
      <div className={'content'}>
        <Title title={t('title')} />
        <div className={'contentActus'}>
          {actualites ? (
            Object.values(actualites)
              .sort((a, b) => b.publication_date - a.publication_date)
              .map((actu) => (
                <SingleNewsBloc
                  key={actu.title}
                  title={actu && actu.title}
                  text={actu.intro}
                  date={(actu.publication_date && formatDateCourt(actu.publication_date.toString())) || ''}
                  tag={actu.type.label}
                  link={actu.url}
                  image={actu.img}
                />
              ))
          ) : (
            <p>
              Les actualités ont été bloquées par vos préférences de cookies, pour Les réinitialiser,
              <Button color="primary" variant="text" title="cliquez ici" handleClick={setCookies} />
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default NewsBloc;
