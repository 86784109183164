import { Box } from '@mui/material';
import style from './styles';
import { useTranslation } from 'react-i18next';
import Link from '../Link';

interface Props {
  title: string;
  text: string;
  image: string;
  tag?: string;
  date?: string;
  link?: string;
}

function SingleNewsBloc(props: Props) {
  const { text, title, image, tag, date, link } = props;

  const { t } = useTranslation('news');
  return (
    <Box className={'blocText'} sx={style}>
      <div>
        <div className={'image'} style={{ backgroundImage: "url('" + image + "')" }}>
          <span className={'tag'}>
            <span>{tag}</span>
          </span>
        </div>
        <div className={'contentActu'}>
          <span className={'date'}>{date}</span>
          <h4 className={'title'}>{title}</h4>
          <div className={'text'}>
            <div className={'trait'} />
            <p dangerouslySetInnerHTML={{ __html: text }} />
          </div>
          {link && (
            <Link href={link} className={'link'} target="_blank">
              <div className={'traitHorizontal'} />
              <span>{t('readMore')}</span>
            </Link>
          )}
        </div>
      </div>
    </Box>
  );
}

export default SingleNewsBloc;
